import "./assets/css/Login.css";
import "./assets/css/App.css";
import React from "react";
import ReactDOM from "react-dom/client";
//import ColorPicker from "./components/MenuPrincipal/ColorPicker";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./routes/AppRouter.jsx";
import { SnipcartProvider } from "use-snipcart"
//import  Editmenu from "./MenuEditResponsive";
//import Category from "./components/MenuPrincipal/categoryMenu";
//import Partmenu from "./ResponsiveMenu";
//import Threeview from "./scripts/vista3d.js";
//import { CarModelProvider } from './context/CarModelContext';
//import Navbar from "./scripts/Nav.js";
//import { Environment } from "./components/cybertruck/Environment.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <SnipcartProvider>
                <AppRouter />
            </SnipcartProvider>
        </BrowserRouter>
   </React.StrictMode>
);

  

// const root = ReactDOM.createRoot(document.getElementById('root'));
//  root.render(
//    <React.StrictMode>
//      <div className='Login'>
//        <Formulario/>
//      </div>
//    </React.StrictMode>
//  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
