import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from '../pages/Loading';
import { Suspense } from 'react';

const LandingPage = lazy(() => import("../pages/landing/Landing.jsx"));
const PlatformPage = lazy(() => import("../pages/Platform"));
const DashboardPage = lazy(() => import("../pages/Dashboard"));
const LoginPage = lazy(() => import("../pages/Login"));
const DesignPage = lazy(() => import("../pages/design/Design"));
const Myplatformpage  = lazy(() => import("../pages/Myplatform.jsx"));
const Garage = lazy(() => import("../pages/garage/Garage.jsx"));
const CheckoutPage = lazy(() => import("../pages/Checkout.jsx"));

export const AppRouter = () => {

    return (
        <Suspense fallback={<Loading/>}>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/platform" element={<PlatformPage />} />
                <Route path="/garage" element={<Garage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/login" element={<LoginPage />} />  
                <Route path="/design" element={<DesignPage />} />
                <Route path="/Myplatform" element={<Myplatformpage />} />
                <Route path="/Checkout" element={<CheckoutPage />} />
            </Routes>
        </Suspense>
    )

};